export default {
  AllocationMarket: {
    en: 'Welcome to the MMPRO NFT page',
    ja: '配分市場'
  },
  ConnectWalletMessage: {
    en: 'Here you can choose one of the NFT tickets to participate in the contest',
    ja: 'ウォレットを接続して、METAMASKシェアの割り当てを取得します'
  },
  MetamaskPricesMessage: {
    en: 'Choose wisely, decide quickly',
    ja: ''
  }
}